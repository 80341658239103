<template>
  <div class="container" id="main-page">
    <div class="w-11/12 md:w-5/6 m-auto pt-4">
      <div class="container p-2">
        <div class="row justify-content-center row-header">
          <div class="col-12 container-back">
            <div class="history-header">
              <Back />
              <span> Mengapa Sekolah Desain</span>
            </div>
          </div>

          <div class="bg-white mt-3 border-lg">
            <div class="col-12 card-margin">
              <!-- section 0 -->
              <section class="">
                <div class="row justify-content-center align-items-center">
                  <div class="col-md-12">
                    <p class="desc-title">Kenalan Yuk!</p>

                    <hr class="yellow-line ml-0" />
                  </div>
                </div>
              </section>

              <!-- section 1 -->
              <section class="mb-4">
                <div class="row justify-content-center align-items-center">
                  <div class="col-md-6">
                    <p class="first-desc-sub">
                      <b>Sekolah Desain adalah</b> platform belajar online
                      desain terbesar di Indonesia. Kami menghadirkan sistem
                      pembelajaran yang seru dan interaktif, layanan bimbingan
                      belajar dan pelatihan daring berbayar untuk semua orang
                      dari berbagai kalangan di bidang desain, seni dan
                      arsitektur.
                    </p>
                    <p class="first-desc-sub">
                      Konten pembelajaran terlengkap dan mudah kini bisa diakses
                      dimanapun dan kapanpun!
                      <b>Kini semua orang bisa desain!</b>
                    </p>
                  </div>

                  <div class="col-md-6">
                    <!-- src="https://www.sekolahdesain.id/assets/images/mentor-dummy.png" -->
                    <img
                      src="https://www.sekolahdesain.id/media/94-owly-logo.png"
                      class="first-desc-img"
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
              </section>

              <!-- section 2 -->
              <section class="mb-4">
                <div class="row justify-content-center align-items-center">
                  <div class="col-md-12">
                    <p class="desc-title text-center">
                      MENGAPA SEKOLAH DESAIN ?
                    </p>
                    <br />
                    <!-- <p class="first-desc-sub text-center">
                      Owly berubah? Jadi apa?
                    </p>
                    <p class="first-desc-sub text-center">
                      <b>Jadi apa yang kau inginkan~ </b>
                    </p> -->
                  </div>
                </div>
              </section>

              <!-- section 3 -->
              <section id="second-desc-second" class="mb-4">
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">1</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      Materi terstruktur dan tugas terukur yang pasti dikoreksi
                    </p>
                    <p>
                      Di Sekolah Desain, materi yang terstruktur dan tugas yang
                      diberikan terukur sesuai dengan materi yang disampaikan.
                      Mentor akan selalu mengoreksi dan memberikan feedback
                      terhadap tugas yang peserta kerjakan.
                    </p>
                  </div>
                </div>

                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">2</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      Bisa ikut rangkaian webinar hingga berkali-kali
                    </p>
                    <p>
                      Masih belum mengerti materi yang disampaikan pada webinar?
                      Nggak perlu khawatir! Kamu dapat mengajukan webinar++ agar
                      semakin memahami materinya.
                    </p>
                  </div>
                </div>
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">3</span>
                  <div class="list-desc">
                    <p class="sub-desc">Materi yang selalu update</p>
                    <p>
                      Temukan segudang informasi yang belum banyak diketahui dan
                      dapat diakses kapan saja bahkan hingga kamu menjadi
                      alumni.
                    </p>
                  </div>
                </div>
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">4</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      Lebih banyak praktik dibandingkan teori
                    </p>
                    <p>
                      Jam terbang untuk praktik adalah salah satu kunci untuk
                      menjadi semakin mahir mendesain. Pembelajaran di Sekolah
                      Desain yang banyak praktiknya akan membantumu yang masih
                      amatir jadi mahir!
                    </p>
                  </div>
                </div>
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">5</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      Fitur Pay Later
                    </p>
                    <p>
                      Di Sekolah Desain, kami dapat membantu peserta yang
                      terkendala dana dengan fitur Paylater. Gabung kelasnya
                      hari ini, bayarnya bisa lain hari!
                    </p>
                  </div>
                </div>

                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">6</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      One by one support oleh mentor yang ahli
                    </p>
                    <p>
                      Mentor yang ahli akan membimbingmu secara step by step dan
                      memastikanmu memahami materi yang diberikan serta
                      professional dalam menjawab kesulitanmu saat belajar
                      desain. Bahkan kamu juga bisa request webinar tambahan
                      jika masih belum menguasai materinya loh!
                    </p>
                  </div>
                </div>
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">7</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      Graduation group
                    </p>
                    <p>
                      Temukan strategi membuat portofolio pada akhir kelas dan
                      juga grup alumni bersama mentor!
                    </p>
                  </div>
                </div>
                <div class="col-12 desc-second-list">
                  <span class="dot-numb-desc">8</span>
                  <div class="list-desc">
                    <p class="sub-desc">
                      100% Garansi uang pasti kembali
                    </p>
                    <p>
                      Belum bisa desain setelah ikut kelasnya? Tenang aja, 100%
                      garansi uang kembali tanpa syarat apapun!
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.border-lg {
  border-radius: 1rem;
}
</style>
